import React, { useState } from 'react'
import ButtonNewV2 from '@SiteComponents/ButtonNewV2'
import DropdownV2 from '@EditorComponents/DropdownV2'
import some from 'lodash/some'
import map from 'lodash/map'
import Add from '@Images/add_sso.svg'

import './AddUsersToSsoForm.scss'

interface AddUsersToSsoFormProps  {
  addUsers(emails: string[], isAdmin: boolean): void
}

const EMAIL_REGEX = /^\w+([.-]?\w+)@\w+([.-]?\w+)(.\w{1,63})+$/ // As used in site/pages/UsersSignUp/SignUpFormStep1.tsx
const ADMIN_ROLE = 'Admin - Full administrative access'
const MEMBER_ROLE = 'Member - Limited access'
const ROLES = [MEMBER_ROLE, ADMIN_ROLE]

const AddUsersToSsoForm: React.FC<AddUsersToSsoFormProps> = props => {
  const [role, setRole] = useState(MEMBER_ROLE)
  const [emailString, setEmailString] = useState('')
  const [emails, setEmails] = useState<string[]>([])
  const [showInfo, setShowInfo] = useState(false)
  const [infoTimeout, setInfoTimeout] = useState<ReturnType<typeof setTimeout> | null>(null)

  const shouldDisableButtonBecauseOfMissingEmails = emails.length === 0
  const shouldDisableButtonBecauseOfIncorrectEmails = some(emails, email => !EMAIL_REGEX.test(email))
  const shouldShowInfo = showInfo && shouldDisableButtonBecauseOfIncorrectEmails

  const showInfoAboutEmailFormat = () => {
    if (!shouldDisableButtonBecauseOfIncorrectEmails || infoTimeout) { return }

    const newInfoTimeout = setTimeout(() => {
      setShowInfo(true)
    }, window.Checksub.tooltipDelay)
    setInfoTimeout(newInfoTimeout)
  }

  const hideInfoAboutEmailFormat = () => {
    if (!shouldDisableButtonBecauseOfIncorrectEmails || !infoTimeout) { return }

    setShowInfo(false)
    clearTimeout(infoTimeout)
    setInfoTimeout(null)
  }

  const addUsers = () => {
    props.addUsers(emails, role === ADMIN_ROLE)
  }

  const handleChangeEmailString = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newEmailString = e.target.value
    setEmailString(newEmailString)

    const newEmails = map(newEmailString.split(','), email => email.toLowerCase().trim())
    setEmails(newEmails)
  }

  const renderEmails = () => {
    return (
      <div className="AddUsersToSsoForm__input">
        <div className="AddUsersToSsoForm__input-label">
            Emails
        </div>
        <textarea
          className="AddUsersToSsoForm__input-emails"
          value={emailString}
          onChange={handleChangeEmailString}
          placeholder="email@example.com, email2@example.com"
        />
      </div>
    )
  }

  const renderRoles = () => {
    return (
      <div className="AddUsersToSsoForm__input">
        <div className="AddUsersToSsoForm__input-label">
            Add as
        </div>
        <DropdownV2
          items={ROLES}
          currentItem={role}
          onChange={setRole}
          listWidth={481}
          noPortal
          dropdownX={0}
          dropdownY={45}
        />
      </div>
    )
  }

  const renderButton = () => {
    return (
      <div className="AddUsersToSsoForm__button-wrapper">
        <div
          className="AddUsersToSsoForm__button"
          onMouseEnter={showInfoAboutEmailFormat}
          onMouseLeave={hideInfoAboutEmailFormat}
        >
          <ButtonNewV2
            type="button"
            styleType="brand-primary"
            size="large"
            caption="Add users"
            icon={<Add />}
            onClick={addUsers}
            disabled={shouldDisableButtonBecauseOfMissingEmails || shouldDisableButtonBecauseOfIncorrectEmails}
          />
        </div>
        {shouldShowInfo && (
          <div className="AddUsersToSsoForm__tooltip">
            At least one email has an incorrect format
          </div>
        )}
      </div>
    )
  }

  return(
    <div className="AddUsersToSsoForm">
      {renderEmails()}
      {renderRoles()}
      {renderButton()}
    </div>
  )
}

export default AddUsersToSsoForm
