import axios from 'axios'
import convertToSnakeCase from '@Utils/ConvertToSnakeCase'
import { IStripeToken } from './types'

export function fetchOrderPrice(orderId: number) {
  return axios.get(`/api/v1/orders/${orderId}/show_price`)
}

export function fetchCreditPrice(creditId: number) {
  return axios.get(`/api/v1/credits/${creditId}/show_price`)
}

export function updateStripeToken(data: IStripeToken) {
  return axios.put(`/api/v1/users/update_token`, convertToSnakeCase(data))
}
