import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch, Action } from 'redux'
import { IApplicationState } from '@Site/rootReducer'
import TableHeader from '@SiteComponents/TableHeader'

import {
  ILabelState,
  IProjectVersionLabelsPayload
} from '@SiteModules/Label/types'

import {
  IProjectVersion,
  IDeleteProjectVersionPopup,
  IOpenExportationPayload,
} from '@SiteContainers/ProjectVersionsList/types'

import {
  getLabelIds,
  getLabelCaptions,
  getLabelsInAllSelectedProjectVersions,
  getLabelsInNoSelectedProjectVersion,
} from '@SiteModules/Label/selectors'

import {
  getDisabledProjectVersionsSelected,
  getProjectVersionsWithoutAssetsSelected,
  getProjectVersionsThatBelongToManualProjectSelected,
  getProjectVersionsThatBelongToTtsProjectSelected,
  getProjectVersionsWithLanguageSelected,
  getSubtitleIdsOfSelectedProjectVersions,
  getProjectVersionsWithFreeCaptioningSelected,
  getProjectVersionsWithAnySubscriptionSubtitlesSelected,
  getProjectVersionsWithAtLeastOneProcessedSubtitleSelected,
} from '@SiteContainers/ProjectVersionsList/selectors'

import {
  openDeleteProjectVersionPopup,
  openExportationPopup,
  increasePageCount,
  decreasePageCount,
} from '@SiteContainers/ProjectVersionsList/actions'

import {
  deleteProjectVersionLabels,
  createProjectVersionLabels,
} from '@SiteModules/Label/actions'

interface TableHeaderContainerProps {
  readonly projectVersions: IProjectVersion[]
  readonly totalCount: number
  readonly totalPages: number
  readonly currentPage: number
  readonly labelState: ILabelState
  readonly selectedProjectVersions: string[]
  readonly labelsInNoSelectedProjectVersion: number[]
  readonly labelsInAllSelectedProjectVersions: number[]
  readonly disabledProjectVersionsSelected: boolean
  readonly projectVersionsWithoutAssetsSelected: boolean
  readonly projectVersionsWithLanguageSelected: boolean
  readonly subtitleIdsOfSelectedProjectVersions: number[]
  readonly projectVersionsThatBelongToManualProjectSelected: boolean
  readonly projectVersionsThatBelongToTtsProjectSelected: boolean
  readonly projectVersionsWithFreeCaptioningSelected: boolean
  readonly projectVersionsWithAtLeastOneProcessedSubtitleSelected: boolean
  readonly hasCurrentSubscription: boolean
  readonly projectVersionsWithAnySubscriptionSubtitlesSelected: boolean
  increasePageCount(): void
  decreasePageCount(): void
  openCreateLabelPopup(): void
  openDeleteProjectVersionPopup(payload: IDeleteProjectVersionPopup): void
  deleteProjectVersionLabels(payload: IProjectVersionLabelsPayload): void
  createProjectVersionLabels(payload: IProjectVersionLabelsPayload): void
  openExportationPopup(payload: IOpenExportationPayload): void
}

interface TableHeaderContainerState {
}

class TableHeaderContainer extends React.Component<TableHeaderContainerProps, TableHeaderContainerState> {
  openDeleteProjectVersionPopup = () => {
    const payload = { projectVersionIds: this.props.selectedProjectVersions }
    this.props.openDeleteProjectVersionPopup(payload)
  }

  deleteProjectVersionLabels = (labelId: number) => {
    const payload = { labelId, selectedProjectVersions: this.props.selectedProjectVersions }
    this.props.deleteProjectVersionLabels(payload)
  }

  createProjectVersionLabels = (labelId: number) => {
    const payload = { labelId, selectedProjectVersions: this.props.selectedProjectVersions }
    this.props.createProjectVersionLabels(payload)
  }

  openExportationPopup = () => {
    const payload = { subtitleIds: this.props.subtitleIdsOfSelectedProjectVersions }
    this.props.openExportationPopup(payload)
  }

  render() {
    const {
      projectVersions,
      labelState,
      totalCount,
      totalPages,
      currentPage,
      selectedProjectVersions,
      openCreateLabelPopup,
      labelsInAllSelectedProjectVersions,
      labelsInNoSelectedProjectVersion,
      disabledProjectVersionsSelected,
      projectVersionsWithoutAssetsSelected,
      projectVersionsWithLanguageSelected,
      projectVersionsThatBelongToManualProjectSelected,
      projectVersionsThatBelongToTtsProjectSelected,
      projectVersionsWithFreeCaptioningSelected,
      projectVersionsWithAtLeastOneProcessedSubtitleSelected,
      hasCurrentSubscription,
      projectVersionsWithAnySubscriptionSubtitlesSelected,
    } = this.props


    return (
      <React.Fragment>
        <TableHeader
          createProjectVersionLabels={this.createProjectVersionLabels}
          deleteProjectVersionLabels={this.deleteProjectVersionLabels}
          numberOfSelectedProjectVersions={selectedProjectVersions.length}
          disabledProjectVersionsSelected={disabledProjectVersionsSelected}
          openDeleteProjectVersionPopup={this.openDeleteProjectVersionPopup}
          openCreateLabelPopup={openCreateLabelPopup}
          labelCaptions={getLabelCaptions(labelState)}
          labelIds={getLabelIds(labelState)}
          labelsInAllSelectedProjectVersions={labelsInAllSelectedProjectVersions}
          labelsInNoSelectedProjectVersion={labelsInNoSelectedProjectVersion}
          totalCount={totalCount}
          totalPages={totalPages}
          currentPage={currentPage}
          increasePageCount={this.props.increasePageCount}
          decreasePageCount={this.props.decreasePageCount}
          shouldRenderPagination={!!projectVersions.length}
          projectVersionsWithoutAssetsSelected={projectVersionsWithoutAssetsSelected}
          projectVersionsWithLanguageSelected={projectVersionsWithLanguageSelected}
          projectVersionsThatBelongToManualProjectSelected={projectVersionsThatBelongToManualProjectSelected}
          projectVersionsThatBelongToTtsProjectSelected={projectVersionsThatBelongToTtsProjectSelected}
          openExportationPopup={this.openExportationPopup}
          projectVersionsWithFreeCaptioningSelected={projectVersionsWithFreeCaptioningSelected}
          projectVersionsWithAtLeastOneProcessedSubtitleSelected={projectVersionsWithAtLeastOneProcessedSubtitleSelected}
          hasCurrentSubscription={hasCurrentSubscription}
          projectVersionsWithAnySubscriptionSubtitlesSelected={projectVersionsWithAnySubscriptionSubtitlesSelected}
        />
      </React.Fragment>
    )
  }
}

function mapStateToProps(state: IApplicationState) {
  const { projectVersionsList, labels, user } = state
  return {
    projectVersions: projectVersionsList.projectVersions,
    selectedProjectVersions: projectVersionsList.selectedProjectVersions,
    labelState: labels,
    labelsInAllSelectedProjectVersions: getLabelsInAllSelectedProjectVersions(state),
    labelsInNoSelectedProjectVersion: getLabelsInNoSelectedProjectVersion(state),
    disabledProjectVersionsSelected: getDisabledProjectVersionsSelected(state.projectVersionsList),
    projectVersionsWithoutAssetsSelected: getProjectVersionsWithoutAssetsSelected(state.projectVersionsList),
    projectVersionsWithLanguageSelected: getProjectVersionsWithLanguageSelected(state.projectVersionsList),
    projectVersionsThatBelongToManualProjectSelected: getProjectVersionsThatBelongToManualProjectSelected(state.projectVersionsList),
    projectVersionsThatBelongToTtsProjectSelected: getProjectVersionsThatBelongToTtsProjectSelected(state.projectVersionsList),
    subtitleIdsOfSelectedProjectVersions: getSubtitleIdsOfSelectedProjectVersions(state.projectVersionsList),
    totalCount: projectVersionsList.totalCount,
    totalPages: projectVersionsList.totalPages,
    currentPage: projectVersionsList.currentPage,
    projectVersionsWithFreeCaptioningSelected: getProjectVersionsWithFreeCaptioningSelected(state.projectVersionsList),
    projectVersionsWithAnySubscriptionSubtitlesSelected: getProjectVersionsWithAnySubscriptionSubtitlesSelected(state.projectVersionsList),
    projectVersionsWithAtLeastOneProcessedSubtitleSelected: getProjectVersionsWithAtLeastOneProcessedSubtitleSelected(state.projectVersionsList),
    hasCurrentSubscription: user.subscription.attributes.isCurrent,
  }
}

function mapDispatchToProps(dispatch: Dispatch<Action>) {
  return bindActionCreators({
    openDeleteProjectVersionPopup,
    openExportationPopup,
    deleteProjectVersionLabels,
    createProjectVersionLabels,
    increasePageCount,
    decreasePageCount,
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(TableHeaderContainer)
