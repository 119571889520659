import React, { useState } from 'react'
import classnames from 'classnames'

interface CheckboxProps {
  readonly checked: boolean
  readonly label: string
  readonly disabled?: boolean
  setChecked(newChecked: boolean): void
}

const Checkbox: React.FC<CheckboxProps> = props => {
  const {
    checked,
    label,
    disabled,
  } = props

  const [active, setActive] = useState(false)

  const handleActiveChange = () => {
    if (disabled) { return }

    setActive(!active)
  }

  const handleChange = (e: any) => {
    if (disabled) { return }

    props.setChecked(e.target.checked)
  }

  return (
    <div className="ProjectVersionSettings__checkbox">
      <label className="ProjectVersionSettings__checkbox-label">
        <input
          type="checkbox"
          onClick={handleChange}
          checked={checked}
          disabled={disabled}
        />
        <span
          className={classnames(
            'ProjectVersionSettings__checkbox-checkmark',
            {'ProjectVersionSettings__checkbox-checkmark-disabled': disabled},
            {'ProjectVersionSettings__checkbox-checkmark-active': active}
          )}
        />
        <span
          className="ProjectVersionSettings__checkbox-text"
          onMouseEnter={handleActiveChange}
          onMouseLeave={handleActiveChange}
        >
          {label}
        </span>
      </label>
    </div>
  )
}

export default Checkbox
