import React from 'react'
import Popup from '@EditorComponents/Popup'
import Themes from '@SiteContainers/Themes'
import showConfetti from '@Utils/ShowConfettiAnimation'

import './SetThemePopup.scss'

const SHORT_TIMESPAN = 2000 // ms

interface SetThemePopupProps {
  closePopup(): void
}

const SetThemePopup: React.FC<SetThemePopupProps> = props => {
  const close = () => {
    props.closePopup()
    showConfetti()

    setTimeout(() => {
      window.location.href = '/'
    }, SHORT_TIMESPAN)
  }

  return(
    <Popup
      className="SetThemePopup"
      closePopup={close}
    >
      <Themes/>
    </Popup>
  )
}

export default SetThemePopup
