import React  from 'react'
import LogoIcon from '@Images/logo.svg'
import SalesTeam from '@Images/sales_team.png'

import './DemoHeader.scss'

interface DemoHeaderProps {
}

const DemoHeader: React.FC<DemoHeaderProps> = props => {
  return (
    <header className="DemoHeader">
      <div className="DemoHeader__left">
        <LogoIcon className="DemoHeader__logo" />
      </div>
      <div className="DemoHeader__center">
        <span className="DemoHeader__meet-us-long">Come and meet us Booth: I11</span>
        <span className="DemoHeader__meet-us-short">Meet us Booth: I11</span>
      </div>
      <div className="DemoHeader__right">
        <a
          href="https://meetings-eu1.hubspot.com/florian-stegre/all-for-content-demo"
          className="DemoHeader__book-meeting"
          target="_blank"
        >
          Book a meeting
        </a>
        <img src={SalesTeam}/>
      </div>
    </header>
  )
}

export default DemoHeader
