import { Action } from 'redux'
import { put, takeLatest } from 'redux-saga/effects'
import * as Eff from 'redux-saga/effects' // tslint:disable-line:no-duplicate-imports
import showConfetti from '@Utils/ShowConfettiAnimation'

import Logger from '@Utils/Logger'

import {
  closeThemePopup
} from './actions'

import {
  ThemesActionTypes,
  ISetThemePayloadBody
} from './types'

import * as API from './api'

interface IAction extends Action {
  readonly payload: object
}

const call: any = Eff.call

const SHORT_TIMESPAN = 2000 // ms

function* setThemeSaga(action: IAction): Generator<any, any, any> {
  const payload = action.payload as ISetThemePayloadBody

  try {
    yield call(API.setTheme, { payload })
    yield put(closeThemePopup())

    showConfetti()
    setTimeout(() => {
      window.location.href = '/'
    }, SHORT_TIMESPAN)
  } catch (e) {
    Logger.error(e, 'Request could not be submitted')
  }
}

function* themesSagas() {
  yield takeLatest(ThemesActionTypes.SET_THEME, setThemeSaga)
}

export default themesSagas
