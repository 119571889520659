import {
  IProjectVersion,
  IDeleteProjectVersionPopup,
  ProjectVersionActionTypes,
  ISubtitle,
  IOpenImportSrtPayload,
  IOpenExportationPayload,
  IOpenRateSubtitlePayload,
  IFetchOrderPayload,
  IUpdateProjectVersionsPayload,
  TProjectVersionsListActionTypes,
  IChangeSelectedProjectVersionsPayload,
  IUPdateProjectVersionSuccessResponsePayload,
  IRateSubtitlePayload,
} from './types'

import { IOrder } from '@SitePages/ProjectVersionSettings/types'

export const fetchProjectVersions = (): TProjectVersionsListActionTypes => ({
  type: ProjectVersionActionTypes.FETCH_PROJECT_VERSIONS
})

export const loadProjectVersions = (): TProjectVersionsListActionTypes => ({
  type: ProjectVersionActionTypes.LOAD_PROJECT_VERSIONS
})

export const fetchProjectVersionsSuccess = (projectVersions: IProjectVersion[], subtitles: ISubtitle[], totalCount: number, totalPages: number): TProjectVersionsListActionTypes => ({
  type: ProjectVersionActionTypes.FETCH_PROJECT_VERSIONS_SUCCESS,
  projectVersions,
  totalCount,
  totalPages,
  subtitles
})

export const fetchProjectVersionsFailure = (): TProjectVersionsListActionTypes => ({
  type: ProjectVersionActionTypes.FETCH_PROJECT_VERSIONS_FAILURE,
})

export const updateProjectVersions = (payload: IUpdateProjectVersionsPayload): TProjectVersionsListActionTypes => ({
  type: ProjectVersionActionTypes.UPDATE_PROJECT_VERSIONS,
  payload
})

export const updateProjectVersionsSuccess = (payload: IUPdateProjectVersionSuccessResponsePayload): TProjectVersionsListActionTypes => ({
  type: ProjectVersionActionTypes.UPDATE_PROJECT_VERSIONS_SUCCESS,
  payload
})

export const updateProjectVersionsFailure = (): TProjectVersionsListActionTypes => ({
  type: ProjectVersionActionTypes.UPDATE_PROJECT_VERSIONS_FAILURE,
})

export const closePopup = () => ({
  type: ProjectVersionActionTypes.CLOSE_POPUP
})

export const openExternalTokenPopup = (subtitleId: number) => ({
  type: ProjectVersionActionTypes.OPEN_EXTERNAL_TOKEN_POPUP,
  payload: { subtitleId }
})

export const openExportationPopup = (payload: IOpenExportationPayload) => ({
  type: ProjectVersionActionTypes.OPEN_EXPORTATION_POPUP,
  payload
})

export const openRateSubtitlePopup = (payload: IOpenRateSubtitlePayload) => ({
  type: ProjectVersionActionTypes.OPEN_RATE_SUBTITLE_POPUP,
  payload
})

export const openImportSrtPopup = (payload: IOpenImportSrtPayload) => ({
  type: ProjectVersionActionTypes.OPEN_IMPORT_SRT_POPUP,
  payload
})

export const openDeleteProjectVersionPopup = (payload: IDeleteProjectVersionPopup) => ({
  type: ProjectVersionActionTypes.OPEN_DELETE_PROJECT_VERSION_POPUP,
  payload
})

export const changeSelectedProjectVersions = (payload: IChangeSelectedProjectVersionsPayload): TProjectVersionsListActionTypes => ({
  type: ProjectVersionActionTypes.CHANGE_SELECTED_PROJECT_VERSIONS,
  payload
})

export const fetchOrder = (payload: IFetchOrderPayload): TProjectVersionsListActionTypes => ({
  type: ProjectVersionActionTypes.FETCH_ORDER,
  payload
})

export const fetchOrderSuccess = (payload: IOrder): TProjectVersionsListActionTypes => ({
  type: ProjectVersionActionTypes.FETCH_ORDER_SUCCESS,
  payload
})

export const fetchOrderFailure = (): TProjectVersionsListActionTypes => ({
  type: ProjectVersionActionTypes.FETCH_ORDER_FAILURE,
})

export const increasePageCount = (): TProjectVersionsListActionTypes => ({
  type: ProjectVersionActionTypes.INCREASE_PAGE_COUNT,
})

export const decreasePageCount = (): TProjectVersionsListActionTypes => ({
  type: ProjectVersionActionTypes.DECREASE_PAGE_COUNT,
})

export const rateSubtitle = (payload: IRateSubtitlePayload) => {
  return {
    type: ProjectVersionActionTypes.RATE_SUBTITLE,
    payload
  }
}
