import filter from 'lodash/filter'
import some from 'lodash/some'
import find from 'lodash/find'
import map from 'lodash/map'
import every from 'lodash/every'
import capitalize from 'lodash/capitalize'
import { createSelector } from 'reselect'

const PROCESSED_SUBTITLE_STAGES = [
  'New',
  'Done',
  'Waiting customer validation',
  'Processed',
  'Ready',
  'Claim'
]

const PROCESSING_SUBTITLE_STAGES = [
  'processing',
  'generating_voice',
  'lip_syncing',
  'separating_audio',
  'generating_diarization'
]

import {
  ISubtitle,
  IProjectVersionsListState,
} from './types'

export const getFirstEditingProjectVersionId = (state: IProjectVersionsListState): string => state.editingProjectVersionIds.length > 0 ? state.editingProjectVersionIds[0] : ''

export const getSubtitles = (state: IProjectVersionsListState): ISubtitle[] => state.subtitles

export const getFirstEditingProjectVersionSubscriptionSubtitles = createSelector(
  [getFirstEditingProjectVersionId, getSubtitles],
  (editingProjectVersionId, subtitles) => filter(subtitles, subtitle => subtitle.attributes.category === 'subscription' && subtitle.attributes.projectVersionId === editingProjectVersionId)
)

export const getProcessingOrExportingSubtitles = createSelector(
  [getSubtitles],
  (subtitles) => filter(subtitles,
    subtitle => PROCESSING_SUBTITLE_STAGES.includes(subtitle.attributes.stage) || subtitle.attributes.isExporting
  )
)

export const getSubtitleIdsToUpdate = createSelector(
  [getProcessingOrExportingSubtitles],
  (processingOrExportingSubtitles) => processingOrExportingSubtitles.map(subtitle => parseInt(subtitle.id, 10))
)

export const getCurrentProcessedSubtitles = (state: IProjectVersionsListState): ISubtitle[] => {
  const { subtitles, subtitleIds } = state
  const currentProcessedSubtitles = filter(subtitles, (subtitle) => {
    const subtitleStage = capitalize(subtitle.attributes.stage.replace('_', ' '))
    const isProcessed = PROCESSED_SUBTITLE_STAGES.includes(subtitleStage)

    return(subtitleIds.includes(parseInt(subtitle.id, 10)) && isProcessed)
  })
  return currentProcessedSubtitles
}

export const getAnyCurrentProcessedSubtitleHasSpeakers = createSelector(
  [getCurrentProcessedSubtitles],
  (currentProcessedSubtitles) => some(currentProcessedSubtitles, subtitle => subtitle.attributes.hasSpeakers)
)

export const getDisabledProjectVersionsSelected = (state: IProjectVersionsListState): boolean => {
  const { selectedProjectVersions, projectVersions } = state
  return some(selectedProjectVersions, selectedProjectVersionId => {
    const selectedProjectVersion = find(projectVersions, projectVersion => projectVersion.id === selectedProjectVersionId)
    return selectedProjectVersion && selectedProjectVersion.attributes.isDisabled === true
  })
}

export const getProjectVersionsWithoutAssetsSelected = (state: IProjectVersionsListState): boolean => {
  const { selectedProjectVersions, projectVersions } = state
  return some(selectedProjectVersions, selectedProjectVersionId => {
    const selectedProjectVersion = find(projectVersions, projectVersion => projectVersion.id === selectedProjectVersionId)
    return selectedProjectVersion && !selectedProjectVersion.attributes.hasAssets
  })
}

export const getProjectVersionsThatBelongToManualProjectSelected = (state: IProjectVersionsListState): boolean => {
  const { selectedProjectVersions, projectVersions } = state
  return some(selectedProjectVersions, selectedProjectVersionId => {
    const selectedProjectVersion = find(projectVersions, projectVersion => projectVersion.id === selectedProjectVersionId)
    return selectedProjectVersion && selectedProjectVersion.attributes.belongsToManualProject
  })
}

export const getProjectVersionsThatBelongToTtsProjectSelected = (state: IProjectVersionsListState): boolean => {
  const { selectedProjectVersions, projectVersions } = state
  return some(selectedProjectVersions, selectedProjectVersionId => {
    const selectedProjectVersion = find(projectVersions, projectVersion => projectVersion.id === selectedProjectVersionId)
    return selectedProjectVersion && selectedProjectVersion.attributes.belongsToTtsProject
  })
}

export const getProjectVersionsWithLanguageSelected = (state: IProjectVersionsListState): boolean => {
  const { selectedProjectVersions, projectVersions } = state
  return some(selectedProjectVersions, selectedProjectVersionId => {
    const selectedProjectVersion = find(projectVersions, projectVersion => projectVersion.id === selectedProjectVersionId)
    return selectedProjectVersion && selectedProjectVersion.attributes.language
  })
}

export const getProjectVersionsWithFreeCaptioningSelected = (state: IProjectVersionsListState): boolean => {
  const { selectedProjectVersions, projectVersions } = state
  return some(selectedProjectVersions, selectedProjectVersionId => {
    const selectedProjectVersion = find(projectVersions, projectVersion => projectVersion.id === selectedProjectVersionId)
    return selectedProjectVersion && selectedProjectVersion.attributes.hasBeenCaptionedForFree
  })
}

export const getProjectVersionsWithAnySubscriptionSubtitlesSelected = (state: IProjectVersionsListState): boolean => {
  const { selectedProjectVersions, projectVersions } = state
  return some(selectedProjectVersions, selectedProjectVersionId => {
    const selectedProjectVersion = find(projectVersions, projectVersion => projectVersion.id === selectedProjectVersionId)
    return selectedProjectVersion && selectedProjectVersion.attributes.hasAnySubscriptionSubtitles
  })
}

export const getProjectVersionsWithAtLeastOneProcessedSubtitleSelected =  (state: IProjectVersionsListState): boolean => {
  const { selectedProjectVersions, projectVersions, subtitles } = state
  return every(selectedProjectVersions, selectedProjectVersionId => {
    const selectedProjectVersion = find(projectVersions, projectVersion => projectVersion.id === selectedProjectVersionId)
    let selectedProjectVersionHasProcessedSubtitle = false
    if (selectedProjectVersion) {
      subtitles.forEach(subtitle => {
        const subtitleStage = capitalize(subtitle.attributes.stage.replace('_', ' '))
        const isProcessed = PROCESSED_SUBTITLE_STAGES.includes(subtitleStage)
        if (subtitle.attributes.projectVersionId === selectedProjectVersion.id && isProcessed){
          selectedProjectVersionHasProcessedSubtitle = true
        }
      })
    }

    return selectedProjectVersion && selectedProjectVersionHasProcessedSubtitle
  })
}

export const getSubtitleIdsOfSelectedProjectVersions = (state: IProjectVersionsListState): number[] => {
  const { selectedProjectVersions, subtitles } = state
  const subtitlesOfSelectedProjectVersions = filter(subtitles, subtitle => selectedProjectVersions.includes(subtitle.attributes.projectVersionId))

  return map(subtitlesOfSelectedProjectVersions, subtitleOfSelectedProjectVersions => parseInt(subtitleOfSelectedProjectVersions.id, 10))
}
