import React, { useState } from 'react'
import Checkbox from './Checkbox'

interface UploadOptionProps {
  readonly uploadScriptChecked: boolean
  readonly openedForMultipleProjects?: boolean
  setUploadScriptChecked(checked: boolean): void
}

const UploadOption: React.FC<UploadOptionProps> = props => {
  const [showInfoForDisabledUploadOption, setShowInfoForDisabledUploadOption] = useState(false)
  const [infoForDisabledUploadOptionTimeout, setInfoForDisabledUploadOptionTimeout] = useState<ReturnType<typeof setTimeout> | null>(null)

  const toggleInfoForDisabledUploadOption = () => {
    if (infoForDisabledUploadOptionTimeout) {
      setShowInfoForDisabledUploadOption(false)
      clearTimeout(infoForDisabledUploadOptionTimeout)
      setInfoForDisabledUploadOptionTimeout(null)
    } else {
      const newInfoForDisabledUploadOptionTimeout = setTimeout(() => {
        setShowInfoForDisabledUploadOption(true)
      }, window.Checksub.tooltipDelay)
      setInfoForDisabledUploadOptionTimeout(newInfoForDisabledUploadOptionTimeout)
    }
  }

  const {
    uploadScriptChecked,
    openedForMultipleProjects,
    setUploadScriptChecked,
  } = props

  const shouldShowDisabledUploadOption = openedForMultipleProjects
  const shouldShowInfoForDisabledUploadOption = shouldShowDisabledUploadOption && showInfoForDisabledUploadOption

  return (
    <div
      className="ProjectVersionSettingsForm__upload-option"
      onMouseEnter={toggleInfoForDisabledUploadOption}
      onMouseLeave={toggleInfoForDisabledUploadOption}
    >
      <Checkbox
        checked={uploadScriptChecked}
        setChecked={setUploadScriptChecked}
        disabled={shouldShowDisabledUploadOption}
        label="Import a script or SRT file as transcription"
      />
      {shouldShowInfoForDisabledUploadOption && (
        <div className="ProjectVersionSettingsForm__tooltip ProjectVersionSettingsForm__tooltip-upload">
          This option is available when ordering the subtitles<br />
          separately for each project from the dashboard.
        </div>
      )}
    </div>
  )
}

export default UploadOption
