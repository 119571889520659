import React, { useEffect, useState } from 'react'
import classnames from 'classnames'
import Arrow from '@Images/arrow_pv_settings.svg'
import Checkbox from './Checkbox'
import UploadOption from './UploadOption'
import Vocabularies from './Vocabularies'

const DELAY_TO_SHOW_OVERFLOW = 200 // ms, The same time as for the opening/closing animations in ProjectVersionSettingsForm__options-body

interface TranscriptionOptionsProps {
  readonly reviewTranscriptChecked: boolean
  readonly uploadScriptChecked: boolean
  readonly enableUploadOption: boolean
  readonly areThereNewTranslationSubtitlesPresent: boolean
  readonly openedForMultipleProjects: boolean
  readonly canUseVocabularies: boolean
  readonly vocabularyNames: string[]
  readonly selectedVocabularyName: string
  readonly vocabularyChecked: boolean
  readonly noSpeakerDiarizationChecked: boolean
  setReviewTranscriptChecked(newReviewTranscriptChecked: boolean): void
  setUploadScriptChecked(newUploadScriptChecked: boolean): void
  setSelectedVocabularyName(newVocabularyName: string): void
  setVocabularyChecked(newVocabularyChecked: boolean): void
  setNoSpeakerDiarizationChecked(newNoSpeakerDiarizationChecked: boolean): void
}

const TranscriptionOptions: React.FC<TranscriptionOptionsProps> = props => {
  const {
    vocabularyNames,
    vocabularyChecked,
    canUseVocabularies,
    enableUploadOption,
    uploadScriptChecked,
    setVocabularyChecked,
    selectedVocabularyName,
    setUploadScriptChecked,
    reviewTranscriptChecked,
    noSpeakerDiarizationChecked,
    setSelectedVocabularyName,
    openedForMultipleProjects,
    setReviewTranscriptChecked,
    setNoSpeakerDiarizationChecked,
    areThereNewTranslationSubtitlesPresent,
  } = props

  const [bodyOpen, setBodyOpen] = useState(false)
  const [bodyOpenDelayed, setBodyOpenDelayed] = useState(false)

  const shouldRenderReviewTranscript = areThereNewTranslationSubtitlesPresent
  const shouldRenderUpload = enableUploadOption
  const shouldRenderVocabularies = canUseVocabularies

  useEffect(() => {
    if (bodyOpen) {
      showBody() // To adjust the height correctly
    }
  }, [shouldRenderReviewTranscript, shouldRenderUpload, shouldRenderVocabularies, vocabularyChecked])

  const toggleBody = () => {
    // https://stackoverflow.com/questions/13938460/css-transition-auto-height-not-working
    const bodyDiv = document.getElementById('ProjectVersionSettingsForm__options-body-transcription')
    if (!bodyDiv) { return }

    bodyDiv.clientHeight ? closeBody(bodyDiv) : openBody(bodyDiv)
  }

  const showBody = () => {
    const bodyDiv = document.getElementById('ProjectVersionSettingsForm__options-body-transcription')
    if (!bodyDiv) { return }

    openBody(bodyDiv)
  }

  const openBody = (bodyDiv: HTMLElement) => {
    const wrapper = document.querySelector('.ProjectVersionSettingsForm__options-body-inner-wrapper-transcription')
    if (wrapper) {
      bodyDiv.style.height = wrapper.clientHeight + 'px'
      setBodyOpen(true)
      setTimeout(() => {
        setBodyOpenDelayed(true)
      }, DELAY_TO_SHOW_OVERFLOW)
    }
  }

  const closeBody = (bodyDiv: HTMLElement) => {
    bodyDiv.style.height = '0'
    setBodyOpen(false)
    setBodyOpenDelayed(false)
  }

  const renderReviewTranscript = () => {
    return (
      <Checkbox
        checked={reviewTranscriptChecked}
        setChecked={setReviewTranscriptChecked}
        label="Review the transcription before translating"
      />
    )
  }

  const renderUpload = () => {
    return (
      <UploadOption
        uploadScriptChecked={uploadScriptChecked}
        setUploadScriptChecked={setUploadScriptChecked}
        openedForMultipleProjects={openedForMultipleProjects}
      />
    )
  }

  const renderVocabularies = () => {
    return (
      <Vocabularies
        vocabularyChecked={vocabularyChecked}
        setVocabularyChecked={setVocabularyChecked}
        vocabularyNames={vocabularyNames}
        selectedVocabularyName={selectedVocabularyName}
        setSelectedVocabularyName={setSelectedVocabularyName}
      />
    )
  }

  const renderNoSpeakerDiarization = () => {
    return (
      <Checkbox
        checked={noSpeakerDiarizationChecked}
        setChecked={setNoSpeakerDiarizationChecked}
        label="Do not detect changes of speaker"
      />
    )
  }

  const renderBody = () => {
    return (
      <div
        className={classnames(
          'ProjectVersionSettingsForm__options-body',
          {'ProjectVersionSettingsForm__options-body-open': bodyOpenDelayed})
        }
        id="ProjectVersionSettingsForm__options-body-transcription"
      >
        <div className="ProjectVersionSettingsForm__options-body-inner-wrapper-transcription">
          <div className="ProjectVersionSettingsForm__options-items">
            {shouldRenderReviewTranscript && renderReviewTranscript()}
            {shouldRenderUpload && renderUpload()}
            {shouldRenderVocabularies && renderVocabularies()}
            {renderNoSpeakerDiarization()}
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="ProjectVersionSettingsForm__options">
      <div
        className="ProjectVersionSettingsForm__options-header"
        onClick={toggleBody}
      >
        <span>
          Transcription
        </span>
        <Arrow
          className={classnames(
            'ProjectVersionSettingsForm__options-arrow',
            {'ProjectVersionSettingsForm__options-arrow-open': bodyOpen})
          }
        />
      </div>
      {renderBody()}
    </div>
  )
}

export default TranscriptionOptions
