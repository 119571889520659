import React, { useEffect, useState } from 'react'
import classnames from 'classnames'
import Arrow from '@Images/arrow_pv_settings.svg'
import DubbingType from './DubbingType'
import Checkbox from './Checkbox'

const DELAY_TO_SHOW_OVERFLOW = 200 // ms, The same time as for the opening/closing animations in ProjectVersionSettingsForm__options-body

interface DubbingOptionsProps {
  readonly selectedDubbingType: string
  readonly separateAudioChecked: boolean
  readonly adaptTranslationChecked: boolean
  readonly lipSyncChecked: boolean
  readonly hasAudioSeparation: boolean
  readonly subscriptionAllowsTranslationAdaptionForDubbing: boolean
  readonly subscriptionAllowsLipSyncing: boolean
  readonly isAdmin: boolean
  readonly reviewTranscriptChecked: boolean
  readonly hasLanguagesThatDoNotAllowVoiceCloning: boolean
  setSelectedDubbingType(newSelectedDubbingType: string): void
  setSeparateAudioChecked(newSeparateAudioChecked: boolean): void
  setAdaptTranslationChecked(newAdaptTranslationChecked: boolean): void
  setLipSyncChecked(newLipSyncChecked: boolean): void
}

const DubbingOptions: React.FC<DubbingOptionsProps> = props => {
  const {
    selectedDubbingType,
    setSelectedDubbingType,
    separateAudioChecked,
    adaptTranslationChecked,
    lipSyncChecked,
    hasAudioSeparation,
    subscriptionAllowsTranslationAdaptionForDubbing,
    subscriptionAllowsLipSyncing,
    isAdmin,
    setSeparateAudioChecked,
    setAdaptTranslationChecked,
    setLipSyncChecked,
    reviewTranscriptChecked,
    hasLanguagesThatDoNotAllowVoiceCloning,
  } = props

  const [bodyOpen, setBodyOpen] = useState(false)
  const [bodyOpenDelayed, setBodyOpenDelayed] = useState(false)

  const shouldRenderAdaptTranslation = subscriptionAllowsTranslationAdaptionForDubbing || isAdmin
  const shouldRenderLipSync = subscriptionAllowsLipSyncing || isAdmin
  const disabled = reviewTranscriptChecked || hasLanguagesThatDoNotAllowVoiceCloning

  const infoText = reviewTranscriptChecked ? 'With the transcript check option enabled, you can generate the dubbing after the translations have been generated.' : 'Voice cloning isn’t supported for all languages selected. We will create the subtitles and then you will be able to generate the dubbing for each available language individually.'

  useEffect(() => {
    if (disabled) {
      showBody()
    }
  }, [])

  useEffect(() => {
    if (disabled) {
      showBody()
    } else if (bodyOpen) {
      toggleBody()
    }
  }, [reviewTranscriptChecked, hasLanguagesThatDoNotAllowVoiceCloning])

  const toggleBody = () => {
    // https://stackoverflow.com/questions/13938460/css-transition-auto-height-not-working
    const bodyDiv = document.getElementById('ProjectVersionSettingsForm__options-body-dubbing')
    if (!bodyDiv) { return }

    bodyDiv.clientHeight ? closeBody(bodyDiv) : openBody(bodyDiv)
  }

  const showBody = () => {
    const bodyDiv = document.getElementById('ProjectVersionSettingsForm__options-body-dubbing')
    if (!bodyDiv) { return }

    openBody(bodyDiv)
  }

  const openBody = (bodyDiv: HTMLElement) => {
    const wrapper = document.querySelector('.ProjectVersionSettingsForm__options-body-inner-wrapper-dubbing')
    if (wrapper) {
      bodyDiv.style.height = wrapper.clientHeight + 'px'
      setBodyOpen(true)
      setTimeout(() => {
        setBodyOpenDelayed(true)
      }, DELAY_TO_SHOW_OVERFLOW)
    }
  }

  const closeBody = (bodyDiv: HTMLElement) => {
    bodyDiv.style.height = '0'
    setBodyOpen(false)
    setBodyOpenDelayed(false)
  }

  const renderInfo = () => {
    return (
      <div className="ProjectVersionSettingsForm__options-info">
        {infoText}
      </div>
    )
  }

  const renderDubbingType = () => {
    return (
      <DubbingType
        selectedDubbingType={selectedDubbingType}
        setSelectedDubbingType={setSelectedDubbingType}
      />
    )
  }

  const renderSeparateAudio = () => {
    const label = hasAudioSeparation ? 'Regenerate voice isolation' : 'Isolate original voice from audio'

    return (
      <Checkbox
        checked={separateAudioChecked}
        setChecked={setSeparateAudioChecked}
        label={label}
      />
    )
  }

  const renderAdaptTranslation = () => {
    return (
      <Checkbox
        checked={adaptTranslationChecked}
        setChecked={setAdaptTranslationChecked}
        label="Refine Translation with AI"
      />
    )
  }

  const renderLipSync = () => {
    return (
      <Checkbox
        checked={lipSyncChecked}
        setChecked={setLipSyncChecked}
        label="Generate lip-sync"
      />
    )
  }

  const renderItems = () => {
    return (
      <div className="ProjectVersionSettingsForm__options-items">
        {renderDubbingType()}
        {renderSeparateAudio()}
        {shouldRenderAdaptTranslation && renderAdaptTranslation()}
        {shouldRenderLipSync && renderLipSync()}
      </div>
    )
  }

  const renderBody = () => {
    return (
      <div
          className={classnames(
            'ProjectVersionSettingsForm__options-body',
            {'ProjectVersionSettingsForm__options-body-open': bodyOpenDelayed})
          }
          id="ProjectVersionSettingsForm__options-body-dubbing"
        >
        <div className="ProjectVersionSettingsForm__options-body-inner-wrapper-dubbing">
          {disabled ? renderInfo() : renderItems()}
        </div>
      </div>
    )
  }

  return (
    <div
      className={classnames(
        'ProjectVersionSettingsForm__options',
        {'ProjectVersionSettingsForm__options-disabled': disabled})
      }
    >
      <div
        className="ProjectVersionSettingsForm__options-header"
        onClick={toggleBody}
      >
        <span>
          Dubbing
        </span>
        <Arrow
          className={classnames(
            'ProjectVersionSettingsForm__options-arrow',
            {'ProjectVersionSettingsForm__options-arrow-open': bodyOpen})
          }
        />
      </div>
      {renderBody()}
    </div>
  )
}

export default DubbingOptions
