import {
  IThemesState,
  ThemesActionTypes,
  TThemesActionTypes,
  ISetTargetForThemeAction
} from './types'

export const initialState: IThemesState = {
  themes: [
    {
      name: 'Neutral',
      sampleUrl: 'https://checksub-video-asset.s3.eu-west-1.amazonaws.com/theme_preview/neutral-preview.mp4',
      sampleImageUrl: 'https://checksub-video-asset.s3.eu-west-1.amazonaws.com/theme_preview/neutral-preview.png',
    }, {
      name: 'Homoni',
      sampleUrl: 'https://checksub-video-asset.s3.eu-west-1.amazonaws.com/theme_preview/homoni-preview.mp4',
      sampleImageUrl: 'https://checksub-video-asset.s3.eu-west-1.amazonaws.com/theme_preview/homoni-preview.png',
    }, {
      name: 'Papaya',
      sampleUrl: 'https://checksub-video-asset.s3.eu-west-1.amazonaws.com/theme_preview/papaya-preview.mp4',
      sampleImageUrl: 'https://checksub-video-asset.s3.eu-west-1.amazonaws.com/theme_preview/papaya-preview.png',
    }, {
      name: 'Dunan',
      sampleUrl: 'https://checksub-video-asset.s3.eu-west-1.amazonaws.com/theme_preview/dunan-preview.mp4',
      sampleImageUrl: 'https://checksub-video-asset.s3.eu-west-1.amazonaws.com/theme_preview/dunan-preview.png',
    }
  ],
  projectVersionIds: [],
  subtitleLanguages: [],
  openSetThemePopup: false,
  isReadyForTheme: false
}

export const themesReducer = (
  state: IThemesState = initialState,
  action: TThemesActionTypes
): IThemesState => {
  switch (action.type) {
    case ThemesActionTypes.IS_READY_FOR_THEME: return setIsReadyForTheme(state)
    case ThemesActionTypes.SET_TARGET_FOR_THEME: return setTargetForTheme(state, action)
    case ThemesActionTypes.OPEN_SET_THEME_POPUP: return openSetThemePopup(state)
    case ThemesActionTypes.CLOSE_POPUP: return closeThemePopup(state)

    default: return state
  }
}

const setIsReadyForTheme = (state: IThemesState): IThemesState => {
  return { ...state, isReadyForTheme: true }
}

function isISetTargetForThemeAction(arg: any): arg is ISetTargetForThemeAction {
  return arg.payload != null
}

const setTargetForTheme = (state: IThemesState, action: TThemesActionTypes): IThemesState => {
  if (!isISetTargetForThemeAction(action)) {
    return { ...state, projectVersionIds: [], subtitleLanguages: [], isReadyForTheme: false }
  }

  return { ...state, projectVersionIds: action.payload.projectVersionIds, subtitleLanguages: action.payload.subtitleLanguages, isReadyForTheme: false }
}

const openSetThemePopup = (state: IThemesState): IThemesState => {
  return { ...state, openSetThemePopup: true }
}

const closeThemePopup = (state: IThemesState): IThemesState => {
  return {
    ...state,
    openSetThemePopup: false
  }
}
