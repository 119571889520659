import { TActionTypes, IAction } from '@Root/types'

export const DemoActionTypes = {
  SAVE_URL_IN_STATE: '@@demo/SAVE_URL_IN_STATE',
  SUBMIT_DEMO_PROJECT_VERSION_SETTINGS: '@@demo/SUBMIT_DEMO_PROJECT_VERSION_SETTINGS',
  SET_SUBMITTED: '@@demo/SET_SUBMITTED',
}

export interface IDemoState {
  readonly videoUrl: string
  readonly needsDownload: boolean
  readonly submitted: boolean
}

export interface ISaveUrlInStatePayload {
  readonly videoUrl: string
  readonly needsDownload: boolean
}

export interface ISubmitDemoProjectVersionSettingsPayload {
  readonly videoUrl: string
  readonly email: string
  readonly projectVersionLanguage: string
  readonly subtitleLanguages: string[]
  readonly needsDownload: boolean
  readonly dubbing: boolean
  readonly adaptTranslation?: boolean
  readonly syncLips?: boolean
}

export type IDemoActionTypes =
  TActionTypes |
  IAction<ISaveUrlInStatePayload> |
  IAction<ISubmitDemoProjectVersionSettingsPayload>
