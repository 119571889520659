import React, { useState }  from 'react'
import { Link } from 'react-router-dom'
import IconButton from '@SiteComponents/IconButton'
import LabelsDropdown from '@SiteComponents/LabelsDropdown/LabelsDropdown'
import useClickOutsideListener from '@Utils/UseClickOutsideListener'

import Pagination from '@SiteComponents/Pagination'

import Plus from '@Images/plus.svg'
import Folder from '@Images/folder.svg'
import Trash from '@Images/trash.svg'
import Download from '@Images/download-dashboard.svg'

import './TableHeader.scss'

interface TableHeaderProps {
  readonly totalCount: number
  readonly totalPages: number
  readonly currentPage: number
  readonly labelIds: number[]
  readonly labelCaptions: string[]
  readonly labelsInAllSelectedProjectVersions: number[]
  readonly labelsInNoSelectedProjectVersion: number[]
  readonly numberOfSelectedProjectVersions: number
  readonly disabledProjectVersionsSelected: boolean
  readonly projectVersionsWithoutAssetsSelected: boolean
  readonly shouldRenderPagination: boolean
  readonly projectVersionsWithLanguageSelected: boolean
  readonly projectVersionsThatBelongToManualProjectSelected: boolean
  readonly projectVersionsThatBelongToTtsProjectSelected: boolean
  readonly projectVersionsWithFreeCaptioningSelected: boolean
  readonly projectVersionsWithAtLeastOneProcessedSubtitleSelected: boolean
  readonly hasCurrentSubscription: boolean
  readonly projectVersionsWithAnySubscriptionSubtitlesSelected: boolean
  increasePageCount(): void
  decreasePageCount(): void
  openDeleteProjectVersionPopup(): void
  openCreateLabelPopup(): void
  createProjectVersionLabels(labelId: number): void
  deleteProjectVersionLabels(labelId: number): void
  openExportationPopup(): void
}

const TableHeader: React.FC<TableHeaderProps> = props => {
  const VIDEOS_PER_PAGE = 8

  const [openLabelsDropdown, setOpenLabelsDropdown] = useState(false)

  useClickOutsideListener('TableHeader__labels-wrapper', setOpenLabelsDropdown, openLabelsDropdown)

  const toggleShowingLabelsDropdown = () => {
      setOpenLabelsDropdown(!openLabelsDropdown)
  }

  const openCreateLabelPopup = () => {
    setOpenLabelsDropdown(false)
    props.openCreateLabelPopup()
  }

  const {
    totalCount,
    totalPages,
    currentPage,
    numberOfSelectedProjectVersions,
    labelIds, labelCaptions,
    labelsInAllSelectedProjectVersions,
    labelsInNoSelectedProjectVersion,
    disabledProjectVersionsSelected,
    projectVersionsWithLanguageSelected,
    projectVersionsThatBelongToManualProjectSelected,
    projectVersionsThatBelongToTtsProjectSelected,
    shouldRenderPagination,
    projectVersionsWithoutAssetsSelected,
    projectVersionsWithFreeCaptioningSelected,
    projectVersionsWithAtLeastOneProcessedSubtitleSelected,
    hasCurrentSubscription,
    projectVersionsWithAnySubscriptionSubtitlesSelected,
  } = props

  const videoSelection = numberOfSelectedProjectVersions > 0 ? 'videoSelected' : 'noVideoSelected'

  const labelsWrapperStatus = openLabelsDropdown ? 'active' : 'inactive'

  const shouldAllowToAddLanguages = (numberOfSelectedProjectVersions > 1 && !projectVersionsWithLanguageSelected || numberOfSelectedProjectVersions === 1) &&
    !disabledProjectVersionsSelected &&
    !projectVersionsWithoutAssetsSelected &&
    !projectVersionsThatBelongToManualProjectSelected &&
    !projectVersionsThatBelongToTtsProjectSelected

  const wantsToExportSubscriptionSubtitleButHasNoCurrentSubscription = !hasCurrentSubscription && projectVersionsWithAnySubscriptionSubtitlesSelected
  const shouldAllowToExport =
    numberOfSelectedProjectVersions >= 1 &&
    !projectVersionsWithFreeCaptioningSelected &&
    !projectVersionsThatBelongToTtsProjectSelected &&
    !wantsToExportSubscriptionSubtitleButHasNoCurrentSubscription &&
    projectVersionsWithAtLeastOneProcessedSubtitleSelected

  return (
    <div className={`TableHeader TableHeader-${videoSelection}`}>
      {shouldAllowToAddLanguages && (
        <div className="TableHeader__button-wrapper">
          <Link to="/setup">
            <IconButton caption="Add new language">
              <Plus />
            </IconButton>
          </Link>
        </div>
      )}

      {shouldAllowToExport && (
        <div className="TableHeader__button-wrapper TableHeader__button-wrapper-export">
          <IconButton
            caption="Export"
            onClick={props.openExportationPopup}
          >
            <Download />
          </IconButton>
        </div>
      )}

      {numberOfSelectedProjectVersions >= 1 && (
        <div
          className={`TableHeader__button-wrapper TableHeader__labels-wrapper TableHeader__labels-wrapper-${labelsWrapperStatus}`}
        >
          <IconButton
            caption="Labels"
            onClick={toggleShowingLabelsDropdown}
          >
            <Folder />
          </IconButton>
          {openLabelsDropdown && (
            <LabelsDropdown
              createProjectVersionLabels={props.createProjectVersionLabels}
              deleteProjectVersionLabels={props.deleteProjectVersionLabels}
              labelCaptions={labelCaptions}
              labelIds={labelIds}
              openCreateLabelPopup={openCreateLabelPopup}
              labelsInAllSelectedProjectVersions={labelsInAllSelectedProjectVersions}
              labelsInNoSelectedProjectVersion={labelsInNoSelectedProjectVersion}
            />
          )}
        </div>
      )}

      {numberOfSelectedProjectVersions >= 1 && (
        <div className="TableHeader__button-wrapper">
          <IconButton
            caption="Delete the project(s)"
            onClick={props.openDeleteProjectVersionPopup}
            >
            <Trash />
          </IconButton>
        </div>
      )}

      <div className="DashBoardHeader__pagination">
        {shouldRenderPagination &&
          <Pagination
            totalCount={totalCount}
            totalPages={totalPages}
            currentPage={currentPage}
            itemsPerPage={VIDEOS_PER_PAGE}
            increasePageCount={props.increasePageCount}
            decreasePageCount={props.decreasePageCount}
          />
        }
      </div>
    </div>
    )
  }

export default TableHeader
