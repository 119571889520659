import { TActionTypes } from '@Root/types'

export const ThemesActionTypes = {
  SET_THEME: '@@themes/SET_THEME',
  SET_TARGET_FOR_THEME: '@@themes/SET_TARGET_FOR_THEME',
  IS_READY_FOR_THEME: '@@themes/IS_READY_FOR_THEME',
  OPEN_SET_THEME_POPUP: '@@themes/OPEN_SET_THEME_POPUP',
  CLOSE_POPUP: '@@themes/CLOSE_POPUP',
}

export interface IThemesState {
  readonly themes: ITheme[]
  readonly projectVersionIds: string[]
  readonly subtitleLanguages: string[]
  readonly openSetThemePopup: boolean
  readonly isReadyForTheme: boolean
}

export interface ITheme {
  readonly name: string
  readonly sampleUrl: string
  readonly sampleImageUrl: string
}

export interface ISetTargetForThemePayload {
  readonly projectVersionIds: string[]
  readonly subtitleLanguages: string[]
}

export interface ISetTargetForThemeAction {
  readonly type: string
  readonly payload: ISetTargetForThemePayload
}

export interface ISetThemePayload {
  readonly payload: ISetThemePayloadBody
}

export interface ISetThemePayloadBody {
  readonly theme?: string
  readonly projectVersionIds: string[]
  readonly subtitleLanguages: string[]
}

export type TThemesActionTypes = ISetTargetForThemeAction | TActionTypes
