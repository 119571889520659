import {
  IActionSuccess,
  IAction,
} from '@Root/types'

import {
  IOrder,
  IProjectVersionSettings,
  ProjectVersionSettingsActionTypes,
  IProjectVersionSettingsState,
  IProjectVersionSettingsActionTypes,
  IFetchOrderSuccessAction,
  IFetchProjectVersionSettingsSuccessPayload,
  IFetchProjectVersionSettingsMultipleSuccessPayload,
  IFetchProjectVersionSettingsDemoSuccessPayload,
} from './types'

import {
  PaymentActionTypes,
  IPaymentActionTypes,
  IPrice as IOrderPrice, // TODO: fix that!..
} from '@SitePages/CheckoutsPaymentDetails/types'

import {
  CreditsTopupActionTypes
} from '@SitePages/CreditsTopup/types'

import {
  ProjectVersionActionTypes,
  TProjectVersionsListActionTypes
} from '@SiteContainers/ProjectVersionsList/types'

const initialOrder: IOrder = {
  id: '-1',
  type: 'order',
  attributes: {
    id: -1,
    amount: 0.0,
    vat: 0.0,
    isUserAdmin: false,
    userCanUseCredit: false,
    orderedSubtitles: [],
    shouldApplyVat: false,
    paymentIntentClientSecret: ''
  }
}

const initialProjectVersionSettings: IProjectVersionSettings = {
  attributes: {
    title: '',
    duration: 0,
    language: '',
    availableCredits: 0,
    availableSubtitlingTime: 0,
    subtitleLanguages: [],
    languagesForVideos: [],
    translationLanguages: [],
    nonTranslatableLanguages: [],
    alignmentLanguages: [],
    translationFormalityOptionLanguages: [],
    voiceCloningLanguages: [],
    hasMainAssets: false,
    hasAudioSeparation: false,
    subscriptionSubtitlesLength: 0,
  }
}

export const initialState: IProjectVersionSettingsState = {
  price: {
    total: 0,
    vat: false
  },
  loading: true,
  order: initialOrder,
  projectVersionSettings: initialProjectVersionSettings,
}

export const projectVersionSettingsReducer = (
  state: IProjectVersionSettingsState = initialState,
  action: IProjectVersionSettingsActionTypes
): IProjectVersionSettingsState => {
  switch (action.type) {
    case ProjectVersionSettingsActionTypes.FETCH_PROJECT_VERSION_SETTINGS:
    case ProjectVersionSettingsActionTypes.FETCH_PROJECT_VERSION_SETTINGS_MULTIPLE:
    case ProjectVersionSettingsActionTypes.FETCH_PROJECT_VERSION_SETTINGS_DEMO:
      return fetchProjectVersionSettings(state)
    case ProjectVersionSettingsActionTypes.FETCH_PROJECT_VERSION_SETTINGS_SUCCESS:
    case ProjectVersionSettingsActionTypes.FETCH_PROJECT_VERSION_SETTINGS_MULTIPLE_SUCCESS:
    case ProjectVersionSettingsActionTypes.FETCH_PROJECT_VERSION_SETTINGS_DEMO_SUCCESS:
      return fetchProjectVersionSettingsSuccess(state, action)
    case ProjectVersionSettingsActionTypes.PROJECT_VERSION_SETTINGS_FAILURE: return projectVersionSettingsFailure(state)
    case ProjectVersionSettingsActionTypes.SUBMIT_PROJECT_VERSION_UPDATE: return submitProjectVersionUpdate(state)
    case ProjectVersionSettingsActionTypes.SUBMIT_PROJECT_VERSION_UPDATE_SUCCESS: return submitProjectVersionUpdateSuccess(state)
    case ProjectVersionSettingsActionTypes.SUBMIT_PROJECT_VERSION_UPDATE_FAILURE: return submitProjectVersionUpdateFailure(state)
    case PaymentActionTypes.PAYMENT_SUCCESS: return resetOrder(state)
    case PaymentActionTypes.FETCH_ORDER_SUCCESS: return fetchOrderSuccess(state, action)
    case CreditsTopupActionTypes.SUBMIT_TOPUP_REQUEST: return resetOrder(state)
    case ProjectVersionActionTypes.FETCH_ORDER_SUCCESS: return fetchOrderSuccessForCheckout(state, action)
    case ProjectVersionActionTypes.CLOSE_POPUP: return resetProjectVersionSettings(state)
    default: return state
  }
}

const fetchProjectVersionSettings = (state: IProjectVersionSettingsState): IProjectVersionSettingsState => {
  return { ...state, loading: true }
}

const submitProjectVersionUpdate = (state: IProjectVersionSettingsState): IProjectVersionSettingsState => {
  return { ...state, loading: true }
}

const submitProjectVersionUpdateSuccess = (state: IProjectVersionSettingsState): IProjectVersionSettingsState => {
  return { ...state, loading: false }
}

function isIFetchProjectVersionSettingsSuccessAction(arg: any): arg is IAction<IFetchProjectVersionSettingsSuccessPayload | IFetchProjectVersionSettingsMultipleSuccessPayload | IFetchProjectVersionSettingsDemoSuccessPayload> {
  return arg.payload != null && arg.payload.attributes != null
}

const fetchProjectVersionSettingsSuccess = (state: IProjectVersionSettingsState, action: IProjectVersionSettingsActionTypes): IProjectVersionSettingsState => {
  let projectVersionSettings = initialProjectVersionSettings

  if (isIFetchProjectVersionSettingsSuccessAction(action)) {
    const newProjectVersionSettingsAttributes = {...projectVersionSettings.attributes, ...action.payload.attributes}
    projectVersionSettings = { ...projectVersionSettings, attributes: newProjectVersionSettingsAttributes }
  }

  return { ...state, projectVersionSettings, loading: false }
}

const projectVersionSettingsFailure = (state: IProjectVersionSettingsState): IProjectVersionSettingsState => {
  return { ...state, loading: false }
}

const submitProjectVersionUpdateFailure = (state: IProjectVersionSettingsState): IProjectVersionSettingsState => {
  return { ...state, loading: false }
}

const resetOrder = (state: IProjectVersionSettingsState): IProjectVersionSettingsState => {
  return {...state, order: initialOrder }
}

const fetchOrderSuccess = (state: IProjectVersionSettingsState, action: IPaymentActionTypes): IProjectVersionSettingsState => {
  let order = initialOrder

  if (isIFetchOrderSuccessAction(action)) {
    const { id, isUserAdmin, userCanUseCredit, orderedSubtitles, paymentIntentClientSecret } = state.order.attributes
    const orderPrice = action.payload.attributes

    order = {
      id: state.order.id,
      type: 'order',
      attributes: {
        id,
        isUserAdmin,
        userCanUseCredit,
        orderedSubtitles,
        paymentIntentClientSecret,
        amount: orderPrice.amount,
        vat: orderPrice.vat,
        shouldApplyVat: orderPrice.shouldApplyVat,
      }
    }
  }

  return { ...state, order }
}

function isIFetchOrderSuccessAction(arg: any): arg is Required<IActionSuccess<IOrderPrice>> {
  return arg.payload && arg.payload != null
}

const fetchOrderSuccessForCheckout = (state: IProjectVersionSettingsState, action: TProjectVersionsListActionTypes): IProjectVersionSettingsState => {
  let order = initialOrder

  if (isIFetchOrderForCheckoutSuccessAction(action)) {
    order = action.payload
  }

  return { ...state, order }
}

function isIFetchOrderForCheckoutSuccessAction(arg: any): arg is IFetchOrderSuccessAction {
  return arg.payload && arg.payload != null
}

const resetProjectVersionSettings = (state: IProjectVersionSettingsState) => {
  return { ...state, ...initialState }
}
