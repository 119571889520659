import produce, { Draft }  from 'immer'
import { IAction } from '@Root/types'

import {
  IProjectVersionSettingsTtsState,
  IProjectVersionSettingsTtsActionTypes,
  ProjectVersionSettingsTtsActionTypes,
  IFetchProjectVersionSettingsTtsSuccessResponsePayload,
} from './types'

export const initialState: IProjectVersionSettingsTtsState = {
  loading: true,
  dubbingLanguages: [],
  dubbingLanguagesCodes: [],
  voices: []
}

export const projectVersionSettingsTtsReducer = (
  state: IProjectVersionSettingsTtsState = initialState,
  action: IProjectVersionSettingsTtsActionTypes,
): IProjectVersionSettingsTtsState => {
  return produce(state, draft => {
    switch (action.type) {
      case ProjectVersionSettingsTtsActionTypes.FETCH_PROJECT_VERSION_SETTINGS_TTS:
        return fetchProjectVersionSettingsTts(draft)
      case ProjectVersionSettingsTtsActionTypes.FETCH_PROJECT_VERSION_SETTINGS_TTS_SUCCESS:
        return fetchProjectVersionSettingsTtsSuccess(draft, action)
      default: return state
    }
  })
}

const fetchProjectVersionSettingsTts = (draft: Draft<IProjectVersionSettingsTtsState>) => {
  draft.loading = true
}

function isFetchProjectVersionSettingsTtsSuccessAction(arg: any): arg is IAction<IFetchProjectVersionSettingsTtsSuccessResponsePayload> {
  return arg.type === ProjectVersionSettingsTtsActionTypes.FETCH_PROJECT_VERSION_SETTINGS_TTS_SUCCESS
}

const fetchProjectVersionSettingsTtsSuccess = (draft: Draft<IProjectVersionSettingsTtsState>, action: IProjectVersionSettingsTtsActionTypes) => {
  if (!isFetchProjectVersionSettingsTtsSuccessAction(action)) { return }

  draft.dubbingLanguages = action.payload.dubbingLanguages
  draft.dubbingLanguagesCodes = action.payload.dubbingLanguagesCodes
  draft.voices = action.payload.voices
  draft.loading = false
}
